.__logout_modal {
  height: 200px;
  padding: 5px;
}

.__login_modal_description {
  padding: 1em;
}

.__logout_modal .__login_modal_form_button {
  font-size: 16px;
  width: 150px;
  height: 40px;
  margin-top: 20px;
}

.__logout_modal_text {
  color: #888888;
  font-size: 18px;
  font-weight: 350;
  line-height: 10px;
  font-family: sans-serif;
}

@media (max-width: 575px) {
  .__logout_modal .__login_modal_form_button {
    height: 30px;
  }
}