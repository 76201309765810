.desktop_entry_body,.pushable>.pusher {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh!important;
}

.fade-appear, .fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active, .fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit-done{
  opacity: 0;
  display: none;
}

.fade-exit {
  opacity: 0;
  display: none;
}

.fade-exit-active {
  opacity: 0;
  display: none;
}