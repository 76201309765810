.__login_modal {
  height: 400px;
}

.__login_modal .__login_modal_title {
  font-size: 36px;
  font-weight: 500;
}

.__login_modal .__login_modal_description {
  color: #888888;
  font-size: 18px;
  font-weight: 300;
}

.__login_modal .__login_modal_form {
  margin-top: 10px;
  text-align: center;
}

.__login_modal_form .__login_modal_form_button {
  font-size: 16px;
  width: 150px;
  height: 40px;
  margin-top: 20px;
}

.__login_modal_form_field {
  margin-bottom: 1rem;
}

.__login_modal_form_field input {
  background-color: #dddddd!important;
  color: #888!important;
  font-size: 15px!important;
  border-radius: 20px!important;
  width: 350px!important;
  height: 40px!important;
  padding: 0 5px 0 20px!important;
  border: 1px solid #ced4da!important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out!important;
}

.__login_modal_form_field input:focus {
  background-color: #fff!important;
  color: #495057!important;
  border-color: #80bdff!important;
  outline: 0!important;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)!important;
}

.__login_modal_form_email {
  background-color: #dddddd;
  color: #888888;
  font-size: 15px;
  border-radius: 20px;
  width: 350px;
  height: 40px;
  padding: 0 5px 0 20px;
}

.__login_modal_form_password {
  background-color: #dddddd;
  color: #888888;
  font-size: 15px;
  border-radius: 20px;
  width: 350px;
  height: 40px;
  padding: 0 5px 0 20px;
}

.__login_modal_form_forgot_password_content {
  text-align: right;
}

.__login_modal_form_forgot_password {
  /* text-align: right; */
  padding: 5px 10px 5px;
  text-decoration: underline;
  color: rgb(0, 167, 230);
  cursor: pointer;
  display: inline-block;
}

.__login_modal_form_forgot_password:hover {
  color: rgba(0, 0, 0, .6);
}

.__login_modal_form_fail_message {
  color: red;
  font-size: 14px;
  font-weight: 400;
  /* min-height: 19px; */
}

@media screen and (min-width: 426px) {
  .__login_modal {
    max-height: 80vh;
  }
}

@media screen and (max-width: 425px) {
  .__login_modal_form_button {
    width: 120px!important;
    height: 35px!important;
  }
  .__login_modal_form .__login_modal_form_email {
    width: 230px!important;
    height: 35px!important;
  }
  .__login_modal_form .__login_modal_form_password {
    width: 230px!important;
    height: 35px!important;
  }
}