/* DesktopContainer.js top menu center and left portion*/

#desktop__segment__group {
    display: flex;
    height: 36px;
    justify-content: space-between;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
}

@media screen and (min-width:768px) {
    #desktop__segment__group {
        background-color: #d2d2d2;
        padding: 0 40px;
        height: 53px;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    #desktop__segment__group {
        background-color: #d2d2d2;
        padding: 0 20px;
        height: 53px;
    }
}

#desktop__segment__group .desdesktop__segment {
    border: none;
    color: #4d4d4d;
    font-family: "GothamBook", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    padding: 0;
}

#desktop__segment__group .desdesktop__segment a, #desktop__segment__group .desdesktop__segment span {
    color: #4d4d4d;
    font-family: "GothamBook", "Helvetica", "Arial", sans-serif;
    font-weight: 150;
    font-size: 17px;
    text-decoration: none;
    letter-spacing: -0.2px;
    border-bottom: 3px solid transparent;
    border-radius: 0;
}

#desktop__segment__group .desdesktop__segment a:hover {
    background-color: transparent;
    color: #f15a24;
}

#desktop__segment__group .desdesktop__segment .desdesktop_segment_mainmenu {
    padding: 3px 0;
    justify-content: center;
}

#desktop__segment__group .desdesktop__segment .desdesktop_segment_mainmenu .active {
    background-color: #d2d2d2;
    border-bottom: 3px solid #f15a24;
    color: #f15a24;
}

/* DesktopContainer.js top menu right portion*/

#desktop__segment__group .desdesktop__right_segment {
    border: none;
    color: #4d4d4d;
    font-family: "GothamBook", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    padding: 0;
    margin: auto 0;
}

#desktop__segment__group .desdesktop__right_segment a, #desktop__segment__group .desdesktop__right_segment span, #desktop__segment__group .desdesktop__right_segment .desktop__lang__menu, #desktop__segment__group .desdesktop__right_segment .desktop__member__menu {
    color: #4d4d4d;
    font-family: "GothamBook", "Helvetica", "Arial", sans-serif;
    font-weight: 150;
    font-size: 17px;
    text-decoration: none;
    letter-spacing: -0.2px;
    border-radius: 0;
}
#desktop__segment__group .desdesktop__right_segment :hover {
    background-color: #d2d2d2;
}
#desktop__segment__group .desdesktop__right_segment a:hover {
    background-color: #d2d2d2;
    color: #f15a24;
}

#desktop__segment__group .desdesktop__right_segment .active {
    background-color: #d2d2d2;
    color: #f15a24;
}

.desktop__lang__dropdown {
    width: 120px;
    border-radius: 0px;
    -webkit-transform: translate(0, 10%);
    -o-transform: translate(0, 10%);
    transform: translate(0, 10%);
}

.ui.menu .ui.dropdown .menu.desktop__lang__dropdown >.item, .ui.menu .ui.dropdown .menu.desktop__member__dropdown >.item {
    padding: 0.78571429rem 14px!important;
    text-align: left!important;
}

/* logo */

.logo {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
}

.logo span {
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    overflow: hidden;
}

.logo a {
    width: 172px;
    display: block;
}

.logo img {
    width: 100%;
}

@media screen and (max-width:767px) {
    #desktop__segment__group {
        display: flex;
        justify-content: space-between;
    }
    .logo a {
        width: 140px;
    }
}

/* Mobile Language and Products Style*/

.mobile__lang__dropdown, .mobile__products__dropdown {
    width: 100%;
    position: relative!important;
    height: 0;
    border: none!important;
    overflow: hidden;
    transition: .4s!important;
    display: block!important;
}

.mobile__products__dropdown>.item>:first-child {
    padding: 0!important;
}

.mobile__products__dropdown.visible {
    height: 17.8em;
}

.ui.menu .ui.dropdown .mobile__products__dropdown.menu.transition>.item {
    padding-top: .91em!important;
    padding-bottom: 0.1em!important;
}

.ui.menu .ui.dropdown .mobile__products__dropdown.menu.transition>.item>.item {
    padding-top: 0.4em!important;
}

.ui.menu .ui.dropdown .mobile__products__dropdown.menu.transition .item {
    padding-left: 0!important;
}

.visible.mobile__lang__dropdown {
    margin-top: .5em!important;
}

.mobile__lang__dropdown.visible {
    height: 5em;
}

.ui.menu .ui.dropdown .mobile__lang__dropdown.menu.transition .item {
    color: rgba(255, 255, 255, .5) !important;
    padding-left: 0!important;
}

.ui.menu .ui.dropdown .mobile__lang__dropdown.menu.transition .item.__mobile_lang_text {
    font-weight: bold !important;
    color: #fff !important;
}

.ui.dropdown .menu>.divider {
    margin: 0 !important;
    border-top: 1px solid #cdcdcd!important;
}

/* sidebarburger */

.mobile_sidebar_btn {
    width: 40px;
    display: flex;
    padding: 11px 0 10px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.mobile_sidebar_btn .mobile_sidebar_btn_icon {
    width: 23px;
    height: 2px;
    background-color: #696057;
}

.mobile_sidebar_btn i:hover {
    color: #4d4d4d!important;
}

.mobile_sidebar_btn.active .mobile_sidebar_btn_icon {
    background: #f15a24;
}

@media screen and (min-width:768px) {
    .mobile_sidebar_btn {
        padding: 19px 0;
    }
}