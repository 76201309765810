.footer-main {
  font-family: Roboto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  background: #4D4D4D;
  font-weight: 200;
  padding: 10px 0;
}

.copyright-field {
  text-align: center;
  opacity: .8;
  color: #BBBBBB;
}

.footer-link {
  margin-left: 3.4em;
}

.footer-link a {
  text-decoration: underline;
  color: #E6E6E6;
  margin-right: 1.8em;
}

.footer-link a:hover {
  color: #fff;
}

.footer-powered {
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .footer-main {
    font-size: 12px;
  }
  /* .footer-link {
    padding-bottom: 10px;
  } */
  .footer-link a {
    margin-right: 1.4em;
  }
  .footer-link a:hover {
    color: #fff;
  }
  .footer-powered {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .footer-main {
    flex-direction: column-reverse;
  }
  .footer-link {
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .footer-main {
    height: auto;
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-link{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .footer-link a {
    text-align: center;
    font-size: 13px;
    padding: 4px 0;
    margin: 0;
  }
  .footer-link a:last-child {
    order: -1;
  }
}