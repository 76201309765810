.desktop__member__dropdown {
    right: 0!important;
    left: auto!important;
    min-width: 120px!important;
    width: auto!important;
    border-radius: 0px;
    -webkit-transform: translate(0, 13%);
    -o-transform: translate(0, 13%);
    transform: translate(0, 13%);
}

.desktop__member__dropdown .header {
    pointer-events: none;
    text-align: center;
    margin: 0!important;
    padding: 1rem 1rem .75rem!important;
    border-bottom: 1px solid #cdcdcd;
}