body {
  margin: 0;
  padding: 0;
}

.dimmed {
  touch-action: none;
}

.linkButton {
  width: 240px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border: 2px solid #855A27;
  color: #855A27;
  padding: 1em 0;
  margin: auto;
  border-radius: 50px;
}

.__d_linkVideo_block .linkButton {
  width: 192px;
  font-size: 15px;
  padding: .8em 0;
}

.whiteButton {
  background-color: #FFF;
  border: 1px solid #f15a24;
  color: #f15a24;
  text-align: center;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  border-radius: 1.5em;
  width: 10em;
  height: 3em;
  cursor: pointer;
}
.whiteButton:hover {
  background-color: #f15a24;
  border-color: #f15a24;
  color: #FFF;
}

.whiteButton:focus {
  outline: none !important;
}

.whiteButton:active {
  transform: translateY(4px);
}

.orangeButton {
  background-color: #f15a24;
  border-color: #f15a24;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  border-radius: 1.5em;
  width: 10em;
  height: 3em;
  border: none;
  cursor: pointer;
}

.orangeButton:hover {
  background: #d8430d;
}

.orangeButton:focus {
  outline: none !important;
}

.orangeButton:active {
  transform: translateY(4px);
}

@media screen and (min-width:768px) {
  .__d_linkVideo_block {
    width: 14vw;
    margin: 50px auto 0;
  }
  .linkButton {
    width: 14vw;
    font-size: 1.1vw;
    color: #f15a24;
    border-color: #f15a24;
  }
  .__d_linkVideo_block .linkButton {
    width: 80%;
    font-size: 0.88vw;
  }
  .linkButton:hover {
    /* border: 2px solid #d8430d; */
    color: white;
    background-color: #f15a24;
  }
}

@media screen and (min-width:768px) and (max-width:1024px) {
  .__d_linkVideo_block {
    width: 160px;
    margin: 25px auto 0;
  }
  .linkButton {
    width: 100%;
    font-size: 14px;
    color: #f15a24;
    border-color: #f15a24;
  }
  .linkButton:hover {
    /* border: 2px solid #d8430d; */
    color: white;
    background-color: #f15a24;
  }
}

.__d_p_divider {
  color: #d2d2d2;
  background-color: #d2d2d2;
  height: 2px;
  margin: 0;
}