.__forgotPwd_modal form {
    margin: 20px 0 0;
}

.__login_modal_form_forgot_password_email {
    background-color: #dddddd!important;
    color: #888!important;
    font-size: 15px!important;
    border-radius: 20px!important;
    width: 350px!important;
    height: 40px!important;
    padding: 0 5px 0 20px!important;
    border: 1px solid #ced4da!important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out!important;
}

.__login_modal_form_forgot_password_email:focus {
    background-color: #fff!important;
    color: #495057!important;
    border-color: #80bdff!important;
    outline: 0!important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)!important;
}

.__forgotPwd_modal .actions .__forgotPwd_modal_button {
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    width: 150px;
    height: 40px;
}

.__login_modal_form_forgot_password_description {
    white-space: pre-wrap;
}

.__forgotPwd_modal_message {
    min-height: 19px;
    margin: 5px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #f15a24;
    white-space: pre-wrap;
}

.fail_message {
    color: red;
}

@media screen and (max-width: 425px) {
    .__forgotPwd_modal_button {
      width: 120px!important;
      height: 35px!important;
      margin-bottom: 10px;
    }
    .__login_modal_form_forgot_password_email {
      width: 230px!important;
      height: 35px!important;
    }
  }